import React from "react";
import BasePage from "../BasePage";


function WorkEnvironment() {
  return <BasePage title={"Work Environment Thoughts"} subtitle="">

  </BasePage>
}

export default WorkEnvironment
